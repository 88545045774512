import { ReactNode, useEffect } from 'react'
import styled from 'styled-components'
import { animated, useSpring } from '@react-spring/web'

import { FONT_STYLE_SOFIA_11_400, getFontStyles } from '@cellulargoods/styles'

type SiteTooltipProps = {
  active: boolean
  children: ReactNode
  /**
   * used in counterpart with aria-describedby
   * on the parent or what it's related to.
   */
  id: string
  manuallyDismiss: () => void
  className?: string
}

const TOOLTIP_FADE_OUT = 2000

export const SiteTooltip = ({
  active,
  children,
  id,
  manuallyDismiss,
  className,
}: SiteTooltipProps) => {
  const styles = useSpring({
    opacity: active ? 1 : 0,
    y: active ? 0 : 4,
  })

  useEffect(() => {
    const handleKeydown = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        manuallyDismiss()
      }
    }

    if (active) {
      const timeout = window.setTimeout(manuallyDismiss, TOOLTIP_FADE_OUT)
      window.addEventListener('click', manuallyDismiss)
      window.addEventListener('keydown', handleKeydown)

      return () => {
        window.clearTimeout(timeout)
        window.removeEventListener('click', manuallyDismiss)
        window.removeEventListener('keydown', handleKeydown)
      }
    }
  }, [active, manuallyDismiss])

  /**
   * We use a div so any other component
   * using the tooltip can position it
   * without breaking the animation
   */
  return (
    <div className={className}>
      <Tooltip aria-hidden={active} id={id} style={styles}>
        <span>{children}</span>
      </Tooltip>
    </div>
  )
}

const Tooltip = styled(animated.div)`
  ${getFontStyles(FONT_STYLE_SOFIA_11_400)}
  background-color: var(--black);
  color: var(--white);
  padding: 0.5rem 0.5rem 0.3rem 0.5rem;

  & > span {
    position: relative;
    bottom: 0.2rem;
  }
`
