import styled, { css } from 'styled-components'
import { FONT_STYLE_SOFIA_14_500, getFontStyles } from '@cellulargoods/styles'

interface RadioButtonProps {
  label: string
  checked: boolean
  onChange: () => void
}

export const RadioButton = ({ label, checked, onChange }: RadioButtonProps) => {
  return (
    <Label checked={checked}>
      <span>{label}</span>
      <Input type="radio" checked={checked} onChange={onChange} />
    </Label>
  )
}

const Input = styled.input`
  visibility: hidden;
  height: 0;
  width: 0;
  opacity: 0;
  margin: 0;
`

const Label = styled.label<{
  checked: boolean
}>`
  ${getFontStyles(FONT_STYLE_SOFIA_14_500)}
  padding: 4px 18px;
  background: white;
  color: black;
  display: block;
  border: 1px solid black;
  cursor: pointer;

  & > span {
    position: relative;
    bottom: 2px;
  }

  ${({ checked }) =>
    checked &&
    css`
      background: black;
      color: white;
    `}
`
