import styled from 'styled-components'

import { Maybe, Sanity } from '@cellulargoods/types'

import { Media, Text } from '@cellulargoods/core'
import { aspectRatio, MEDIA_QUERIES } from '@cellulargoods/styles'
import { SizesArray } from '@cellulargoods/core/src/components/Image'

export type InternalArticleImageProps =
  Sanity.Keyed<Sanity.InternalArticleImage> & {
    showContent: boolean
  }

export const InternalArticleImage = ({
  articleMedias,
  showContent,
}: InternalArticleImageProps) => {
  const shouldAssetByFullWidth = Boolean(
    Array.isArray(articleMedias) &&
      articleMedias.length === 1 &&
      articleMedias[0]?.fullWidth
  )

  const assetAmount = Number(
    Array.isArray(articleMedias) && articleMedias.length
  )

  return (
    <ArticleImageSection
      assetCount={assetAmount}
      fullWidth={shouldAssetByFullWidth}
      style={{ display: showContent ? 'flex' : 'none' }}
    >
      {Array.isArray(articleMedias) &&
        articleMedias.map((article) =>
          renderArticleMedia(article, {
            fullWidth: shouldAssetByFullWidth,
            assetCount: assetAmount,
          })
        )}
    </ArticleImageSection>
  )
}

type MediaSettings = {
  fullWidth: boolean
  assetCount: number
}

const renderArticleMedia = (
  article: Maybe<Sanity.Keyed<Sanity.ArticleMedia>>,
  { fullWidth, assetCount }: MediaSettings
) => {
  if (!article) {
    return null
  }

  const { articleMediaAsset, mediaFigure } = article

  const asset = Array.isArray(articleMediaAsset) && articleMediaAsset[0]

  let aspectDimensions: {
    imgWidth?: number
    imgHeight?: number
  } = {}

  if (asset && asset._type === 'MediaImage') {
    aspectDimensions = {
      imgWidth: asset.asset?.metadata?.dimensions?.width ?? 0,
      imgHeight: asset.asset?.metadata?.dimensions?.height ?? 0,
    }
  }

  const sizes: SizesArray = fullWidth
    ? ['100vw']
    : assetCount === 1
    ? ['100vw', null, null, '50vw']
    : ['100vw', null, null, '33vw']

  return (
    <ArticleMedia>
      {asset && (
        <ArticleImage {...aspectDimensions}>
          <Media floodParent={false} {...asset} sizes={sizes} />
        </ArticleImage>
      )}
      {mediaFigure ? <ArticleFigure>{mediaFigure}</ArticleFigure> : null}
    </ArticleMedia>
  )
}

const ArticleImageSection = styled.section<{
  fullWidth: boolean
  assetCount: number
}>`
  padding: 0 2rem;
  margin: 4rem 0;

  ${MEDIA_QUERIES.desktopUp} {
    ${(props) =>
      props.fullWidth
        ? `
        grid-column: first / sixth;
      `
        : props.assetCount === 1
        ? `
        grid-column: third / forth;
        padding: 0 4rem;
        `
        : `
        grid-column: second / fifth;
        display: flex;
        `}
    margin: 3rem 0;
  }
`

const ArticleMedia = styled.div`
  width: 100%;

  & + & {
    margin-top: 2rem;
  }

  ${MEDIA_QUERIES.desktopUp} {
    & + & {
      margin-top: 0;
      margin-left: 2rem;
    }
  }
`

const ArticleImage = styled.div<{
  imgWidth?: number
  imgHeight?: number
}>`
  ${(props) =>
    props.imgWidth && props.imgHeight
      ? aspectRatio(props.imgWidth, props.imgHeight)
      : ''}
`

const ArticleFigure = styled(Text)`
  margin-top: 1rem;
  font-size: 1.3rem;
`
