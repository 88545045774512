/**
 * Takes CMS Components and spits them out.
 */
import { Maybe, Sanity, PickType, NonNullSkipArray } from '@cellulargoods/types'
import { SharedCMSRenderer } from '@cellulargoods/core'

// Carousels
import ProductCarousel from '../Carousels/CarouselProduct'

// Product Modules
import OneTileProductPromo from '../Products/ProductPromoOneTile'
import {
  RelatedProductProps,
  RelatedProducts,
} from '../Products/ProductsRelated'

import { InternalArticleRichText } from '../InternalArticles/InternalArticleRichText'
import { InternalArticleQuote } from '../InternalArticles/InternalArticleQuote'
import { InternalArticleImage } from '../InternalArticles/InternalArticleImage'
import { InternalArticleContents } from '../InternalArticles/InternalArticleContents'
import { PromoBannerType } from 'src/pages/products/[category]'

type CMSComponentTypes =
  | NonNullSkipArray<PickType<Sanity.Homepage, 'CMSComponents'>>
  | Sanity.OneTileProductPromo
  | null

/**
 * The Global Renderer for the main CMSComponents.
 *
 * Some CMSComponents will contain a MiniRenderer which will render the
 * specific components isolated to that module, e.g `TwoColumnGrid` has a
 * `MiniRenderer` to render each left and right column items.
 *
 * `product` is passed when using Functional PDP's. This is because the whole
 * page has a referenced product which is queried via GraphQL at the top level,
 * rather than referencing the product multiple times throughout each CMSComponent
 * and having duplicated data within the GraphQL query.
 *
 * So we have
 * ```
 * {
 *   product: obj // We pass down the product obj in the `Renderer`
 *   CMSComponents: ...[]
 * }
 * ```
 *
 * Instead of duplicated data:
 * ```
 * {
 *   CMSComponents: [
 *     {
 *       _type: 'Component_One'
 *       product: obj // Duplicated 🔴
 *     },
 *     {
 *       _type: 'Component_Two'
 *       product: obj // Duplicated 🔴
 *     },
 *   ]
 * }
 * ``
 */
export const WebCMSRenderer = (
  CMSComponent: CMSComponentTypes,
  product?: Maybe<Sanity.ProductWithYotpo> & { promoBanner?: PromoBannerType }
) => {
  if (!CMSComponent) return null

  const sharedComp = SharedCMSRenderer(CMSComponent, false)

  if (!sharedComp) {
    // Note: All components explicitly need a `key` prop, spreading does not apply because
    // Sanity is using `_key`
    switch (CMSComponent._type) {
      case 'OneTileProductPromo': {
        return (
          <OneTileProductPromo
            key={CMSComponent._id}
            {...CMSComponent}
            product={product}
          />
        )
      }
      case 'ProductCarousel': {
        return <ProductCarousel key={CMSComponent._key} {...CMSComponent} />
      }
      case 'RelatedProducts': {
        return (
          <RelatedProducts
            key={CMSComponent._key}
            {...(CMSComponent as RelatedProductProps)}
          />
        )
      }
      default:
        console.warn(
          `Missing Case for ${CMSComponent._type} in WebRenderer`,
          JSON.stringify(CMSComponent)
        )
        return null
    }
  } else {
    return sharedComp
  }
}

type ArticleModules = NonNullSkipArray<
  PickType<Sanity.InternalArticle, 'CMSComponents'>
> | null

export const RenderArticleModule = (
  item: ArticleModules,
  showContent: boolean
) => {
  if (!item) return null

  switch (item._type) {
    case 'InternalArticleImage':
      return (
        <InternalArticleImage
          key={item._key}
          {...item}
          showContent={showContent}
        />
      )
    case 'InternalArticleQuote':
      return (
        <InternalArticleQuote
          key={item._key}
          {...item}
          showContent={showContent}
        />
      )
    case 'InternalArticleRichText':
      return (
        <InternalArticleRichText
          key={item._key}
          {...item}
          showContent={showContent}
        />
      )
    case 'InternalArticleContents':
      return (
        <InternalArticleContents
          key={item._key}
          {...item}
          showContent={showContent}
        />
      )
    default:
      console.warn(`Missing Case for ${item} in RenderArticleModule`)
      return null
  }
}
