import styled from 'styled-components'

import { Sanity } from '@cellulargoods/types'

import { MEDIA_QUERIES } from '@cellulargoods/styles'

import { ProductCarouselBase } from './CarouselProductBase'

export type ProductCarouselProps = Sanity.Keyed<Sanity.ProductCarousel> & {}

// abstraction around Product Carousel to give it the correct props required
const SanityProductCarousel = ({ products }: ProductCarouselProps) => {
  if (!products) {
    return null
  }

  return (
    <ProductCarousel>
      <Base products={products} />
    </ProductCarousel>
  )
}

export default SanityProductCarousel

const ProductCarousel = styled.section`
  padding: 0 2rem 2rem 2rem;

  ${MEDIA_QUERIES.desktopUp} {
    padding: 0 4rem 5.5rem 4rem;
  }
`

const Base = styled(ProductCarouselBase)`
  ${MEDIA_QUERIES.desktopUp} {
    padding: 0;
  }
`
