import { MouseEvent, useEffect, useState } from 'react'
import styled from 'styled-components'

import EmailSvg from 'assets/email.svg'
import FacebookSvg from 'assets/facebook.svg'
import LinkSvg from 'assets/link.svg'
import TwitterSvg from 'assets/twitter.svg'
import { SiteTooltip } from './SiteTooltip'

const SHARE_TEXT = 'Check out this article!'
const EMAIL_SUBJECT = 'Check out this article!'

const EMAIL_BODY = 'Have a look at this article from Cellular Goods: '

export enum SiteSharingVariant {
  Article = 'article',
  Product = 'product',
}

type SiteSharingProps = {
  variant?: SiteSharingVariant
  emailSubject?: string
  emailBody?: string
}

export const SiteSharing = ({
  variant = SiteSharingVariant.Article,
  emailSubject = EMAIL_SUBJECT,
  emailBody = EMAIL_BODY,
}: SiteSharingProps) => {
  const [tooltipActive, setTooltipActive] = useState(false)
  const [{ url, body }, setSharing] = useState({
    url: '',
    body: '',
  })

  const isArticle = variant === SiteSharingVariant.Article

  useEffect(() => {
    setSharing({
      url: encodeURIComponent(window.location.href),
      body: encodeURIComponent(`${emailBody}\r\n\r\n${window.location.href}`),
    })
  }, [emailBody, isArticle])

  const handleClick = (e: MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault()
    openWindow(e.currentTarget.href)
  }

  const handleCopyClick = async () => {
    try {
      const { href } = window.location

      await navigator.clipboard.writeText(href)
      setTooltipActive(true)
    } catch (err) {
      console.warn('There was an issue copying the link to your clipboard!')
    }
  }

  const handleManuallyDismissTooltip = () => {
    setTooltipActive(false)
  }

  return (
    <ShareList small={!isArticle}>
      <ShareItem>
        <ShareAnchor
          href={`http://www.facebook.com/sharer/sharer.php?u=${url}`}
          onClick={handleClick}
          aria-label="Share on Facebook"
        >
          <FacebookSvg
            data-fill
            width={16}
            height={16}
            fill="var(--darkGrey1)"
          />
        </ShareAnchor>
      </ShareItem>
      <ShareItem>
        <ShareAnchor
          onClick={handleClick}
          href={`https://twitter.com/intent/tweet?url=${url}&text=${
            isArticle ? SHARE_TEXT : ''
          }`}
          aria-label="Share on Twitter"
        >
          <TwitterSvg
            data-fill
            width={16}
            height={16}
            fill="var(--darkGrey1)"
          />
        </ShareAnchor>
      </ShareItem>
      <ShareItem>
        <ShareAnchor
          href={`mailto:?body=${body}&subject=${emailSubject}&`}
          aria-label="Share via Email"
        >
          <EmailSvg
            data-stroke
            width={16}
            height={16}
            stroke="var(--darkGrey1)"
          />
        </ShareAnchor>
      </ShareItem>
      <ShareItem>
        <ShareCopiedToolip
          active={tooltipActive}
          manuallyDismiss={handleManuallyDismissTooltip}
          id="copied-success"
        >{`Copied!`}</ShareCopiedToolip>
        <ShareAnchor
          as="button"
          type="button"
          onClick={handleCopyClick}
          aria-label="Copy link"
          aria-describedby="copied-success"
        >
          <LinkSvg data-stroke width={16} stroke="var(--darkGrey1)" />
        </ShareAnchor>
      </ShareItem>
    </ShareList>
  )
}

const ShareItem = styled.li`
  position: relative;
`

const ShareAnchor = styled.a`
  display: block;

  border: none;
  background: transparent;
  cursor: pointer;

  @media (hover: hover) {
    &:hover {
      & > [data-stroke] {
        stroke: var(--accessibleGrey);
      }
      & > [data-fill] {
        fill: var(--accessibleGrey);
      }
    }
  }
`

const ShareList = styled.ul<{ small: boolean }>`
  display: flex;

  ${ShareAnchor} {
    padding: ${(props) => (props.small ? '0.9rem' : '1.2rem')};
  }
`

const ShareCopiedToolip = styled(SiteTooltip)`
  position: absolute;
  bottom: calc(100% - 0.4rem);
  left: 50%;
  transform: translateX(-50%);
`

const openWindow = (url: string, width?: number, height?: number) => {
  const w = width || 550
  const h = height || 350
  const sw = window.screen.width * (window.devicePixelRatio || 1)
  const sh = window.screen.height * (window.devicePixelRatio || 1)
  const x = sw / 2 - w / 2
  const y = sh / 4 - h / 2

  window.open(
    url,
    'share',
    `height=${h},width=${w},top=${y},left=${x},toolbar=0,location=0,menubar=0,directories=0,scrollbars=0`
  )
}
