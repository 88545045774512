import { Media } from '@cellulargoods/core'
import { makeUrlFromType } from '@cellulargoods/core/src/helpers/links'
import { FONT_STYLE_SOFIA_11_400, getFontStyles } from '@cellulargoods/styles'
import { Link, MediaImage } from '@cellulargoods/types/src/sanity'
import NextLink from 'next/link'
import styled from 'styled-components'
const backgroundColors = {
  purple: '#76415E',
  beige: '#DBD2C5',
  blue: '#07364A',
  green: '#54635A',
}

const fontColor = {
  purple: '#ffffff',
  beige: '#000000',
  blue: '#ffffff',
  green: '#ffffff',
}

type Props = {
  title: string
  content?: string
  backgroundColor: keyof typeof backgroundColors
  link: Link
  image?: MediaImage
}

const ProductPromoBanner = ({
  title,
  content,
  backgroundColor,
  link,
  image,
}: Props) => {
  const url = link
    ? makeUrlFromType(link.referenceType as any, [link.link as any])
    : null
  const contents = (
    <Container backgroundColor={backgroundColor}>
      {image ? (
        <ImageContainer>
          <Media {...image} sizes={['100vw', null, null, '33vw']} />
        </ImageContainer>
      ) : null}
      <TextContainer>
        <Title backgroundColor={backgroundColor}>{title}</Title>
        <Text backgroundColor={backgroundColor}>{content}</Text>
      </TextContainer>
    </Container>
  )

  return url ? (
    <NextLink href={url}>
      <A>{contents}</A>
    </NextLink>
  ) : (
    contents
  )
}

const A = styled.a`
  text-decoration: none;
  cursor: pointer;
`
const Container = styled.div<{
  backgroundColor: keyof typeof backgroundColors
}>`
  margin-top: 2rem;
  display: flex;
  gap: 1rem;
  overflow: hidden;
  padding: 1rem;
  background-color: ${(props) => backgroundColors[props.backgroundColor]};
`

const Title = styled.h2<{
  backgroundColor: keyof typeof backgroundColors
}>`
  ${getFontStyles(FONT_STYLE_SOFIA_11_400)}
  color: ${(props) => fontColor[props.backgroundColor]};
  font-weight: bold;
`
const Text = styled.p<{
  backgroundColor: keyof typeof backgroundColors
}>`
  ${getFontStyles(FONT_STYLE_SOFIA_11_400)}
  color: ${(props) => fontColor[props.backgroundColor]};
`

const TextContainer = styled.div`
  display: flex;

  justify-content: center;
  flex-direction: column;
`

const ImageContainer = styled.div`
  position: relative;
  width: 40px;
  height: 40px;
`
export default ProductPromoBanner
