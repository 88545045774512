import { useState } from 'react'
import styled from 'styled-components'
import Link from 'next/link'
import { encode } from 'shopify-gid'

import { Maybe, Sanity } from '@cellulargoods/types'
import {
  TextRenderer,
  Accordion,
  ReviewStarRating,
  Text,
  IsNewTag,
  SoldOutOrnament,
  OnSaleIndicator,
  Heading,
  Button,
  ButtonTypes,
  Bg,
} from '@cellulargoods/core'
import {
  FONT_STYLE_SOFIA_11_400,
  FONT_STYLE_SOFIA_11_500,
  FONT_STYLE_SOFIA_14_500,
  FONT_STYLE_SOFIA_18_500,
  FONT_STYLE_SOFIA_22_500,
  FONT_STYLE_SOFIA_32_500,
  MEDIA_QUERIES,
  getFontStyles,
} from '@cellulargoods/styles'
import { PortableText } from '@portabletext/react'
import { QuantitySelector } from 'components/Input/QuantitySelector'
import { ProductImageCarousel } from 'components/Carousels/CarouselProductImage'
import { PortalNotificationEmail } from 'components/Portals/PortalNotificationEmail'
import { SiteSharing, SiteSharingVariant } from 'components/Site/SiteSharing'

import useProductVariant from 'hooks/useProductVariant'
import { useCart } from 'hooks/useCart'

import { getPrimaryCtaCopy } from 'helpers/shopify'

import { ProductVariantSelector } from './ProductVariantSelector'
import {
  useCurrency,
  useExchangeRate,
  useUKDisclaimer,
} from '@cellulargoods/hooks'
import { PromoBannerType } from 'src/pages/products/[category]'
import ProductPromoBanner from 'components/Marketing/ProductPromoBanner'
import { EVERYTHING_IS_SOLD_OUT } from 'references/constants'

export interface OneTileProductPromoProps extends Sanity.OneTileProductPromo {
  product:
    | (Maybe<Sanity.ProductWithYotpo> & { promoBanner?: PromoBannerType })
    | undefined
}

const OneTileProductPromo = ({
  tabs,
  sliderImages,
  product,
  hasReviewsModule,
}: OneTileProductPromoProps) => {
  const [showNotificationModal, setShowNotificationModal] = useState(false)
  const { lineItemAdd, lineItemMutatingID, loading } = useCart()

  const [quantity, setQuantity] = useState(1)

  const handleChangeQuantity = (newQuantity: number) => {
    setQuantity(newQuantity)
  }

  const [ukDisclaimer] = useUKDisclaimer()

  const {
    product: sanityShopifyProduct,
    title,
    status,
    preorderText,
    product: shopifyProduct,
    reviews,
    showReviews,
    hidePrice,
    strength,
    isNew,
    customTag,
    customTagUs,
    customTagEu,
    hasComparePrice,
    promoBanner,
  } = product as Sanity.ProductWithYotpo & { promoBanner?: PromoBannerType }

  const { activeVariant, updateActiveVariant, productPrice } =
    useProductVariant(
      (shopifyProduct &&
        shopifyProduct.variants) as Sanity.ShopifyProductVariant[]
    )

  const variants = sanityShopifyProduct?.variants

  const availableForSale =
    !EVERYTHING_IS_SOLD_OUT && Boolean(activeVariant.inStock)
  const quantityAvailable = activeVariant.quantityAvailable ?? 0

  const productVariantId = encode('ProductVariant', activeVariant.id) as string

  const handlePrimaryAction =
    (status: Sanity.ProductStatus, available: boolean) => async () => {
      if (
        (status === Sanity.ProductStatus.ACTIVE ||
          status === Sanity.ProductStatus.PREORDER) &&
        available
      ) {
        try {
          if (productVariantId) {
            await lineItemAdd({
              quantity,
              productVariantId: productVariantId,
            })
          }
        } catch (err) {
          console.error(err)
        }
      } else {
        setShowNotificationModal(true)
      }
    }
  const [currency] = useCurrency()
  const exchangeRates = useExchangeRate()

  const price =
    currency === 'USD'
      ? '$' +
        Math.ceil(
          Number(productPrice.split('£')[1]) * exchangeRates[currency][0]
        ).toFixed(2)
      : currency === 'EUR'
      ? '€' +
        Math.ceil(
          Number(productPrice.split('£')[1]) * exchangeRates[currency][0]
        ).toFixed(2)
      : productPrice

  const compareAtPrice = shopifyProduct?.compareAtPrice
    ? shopifyProduct?.compareAtPrice === 0
      ? undefined
      : currency === 'USD'
      ? '$' +
        Math.ceil(
          Number(shopifyProduct?.compareAtPrice) * exchangeRates[currency][0]
        ).toFixed(0)
      : currency === 'EUR'
      ? '€' +
        Math.ceil(
          Number(shopifyProduct?.compareAtPrice) * exchangeRates[currency][0]
        ).toFixed(0)
      : '£' + shopifyProduct?.compareAtPrice?.toFixed(0)
    : null

  const customTagMessage =
    hasComparePrice?.message ||
    hasComparePrice?.messageUs ||
    hasComparePrice?.messageEu

  const handleNotificationClose = () => {
    setShowNotificationModal(false)
  }

  const handleNotifyClick = () => {
    setShowNotificationModal(true)
  }

  if (!product) return null

  return (
    <SectionWrap>
      <ImageWrap>
        {sliderImages && <ProductImageCarousel images={sliderImages} />}
      </ImageWrap>
      <ContentWrap>
        <Tags>
          {isNew ? (
            <IsNew>
              {customTag
                ? currency === 'USD' && customTagUs
                  ? customTagUs
                  : currency === 'EUR'
                  ? customTagEu
                  : customTag
                : `New`}
            </IsNew>
          ) : null}
          {hasComparePrice?.showComparePrice && customTagMessage ? (
            <OnSale
              text={
                currency === 'USD'
                  ? hasComparePrice.messageUs
                  : currency === 'EUR'
                  ? hasComparePrice.messageEu
                  : hasComparePrice.message
              }
            />
          ) : null}
          {!availableForSale && status !== Sanity.ProductStatus.INTEREST && (
            <ProductSoldout small />
          )}
        </Tags>
        <TitlePriceContainer>
          <Heading tag="h2" fontStyle={FONT_STYLE_SOFIA_32_500}>
            {title}
          </Heading>
          <PriceContainer>
            {hasComparePrice?.showComparePrice && compareAtPrice ? (
              <RRP tag="h3">RRP {compareAtPrice}</RRP>
            ) : null}
            {hidePrice ? null : (
              <ProductPrice tag="h3" fontStyle={FONT_STYLE_SOFIA_22_500}>
                {price}
              </ProductPrice>
            )}
          </PriceContainer>
        </TitlePriceContainer>
        {status === Sanity.ProductStatus.PREORDER && preorderText ? (
          <ProductDescription fontStyle={FONT_STYLE_SOFIA_14_500}>
            {preorderText}
          </ProductDescription>
        ) : null}
        {((strength && strength.show) ||
          showReviews ||
          variants!.length > 1) && (
          <ProductMeta>
            {variants!.length > 1 ? (
              <ProductVariantSelection
                showLegend
                variants={variants as Sanity.ShopifyProductVariant[]}
                activeVariant={activeVariant}
                onChange={updateActiveVariant}
              />
            ) : null}
            <MetaFlex>
              {showReviews &&
                (hasReviewsModule && reviews.score !== 0 ? (
                  <Link href="#reviews" passHref>
                    <ProductStars>
                      <ReviewStarRating
                        reviewCount={reviews.totalReviews}
                        rating={reviews.score}
                        label={`Read ${reviews.totalReviews} Review${
                          reviews.totalReviews === 1 ? '' : 's'
                        }`}
                      />
                    </ProductStars>
                  </Link>
                ) : reviews.score !== 0 ? (
                  <ProductStars>
                    <ReviewStarRating
                      reviewCount={reviews.totalReviews}
                      rating={reviews.score}
                      label={`${reviews.totalReviews} Review${
                        reviews.totalReviews === 1 ? '' : 's'
                      }`}
                    />
                  </ProductStars>
                ) : null)}
              {strength && strength.show ? (
                <StrengthRating>
                  <StrengthTitle
                    tag="legend"
                    fontStyle={FONT_STYLE_SOFIA_11_400}
                  >{`${strength.value} strength`}</StrengthTitle>
                  <StrengthBar $value={strength.value} />
                </StrengthRating>
              ) : null}
            </MetaFlex>
          </ProductMeta>
        )}
        <ProductActions
          primaryCtaHasMessage={status === Sanity.ProductStatus.PREORDER}
        >
          {availableForSale && status !== Sanity.ProductStatus.INTEREST && (
            <ProductQuantitySelector
              showLegend
              quantity={quantity}
              maxQuantityLimit={quantityAvailable}
              onChangeQuantity={handleChangeQuantity}
            />
          )}
          <PrimaryActionContainer>
            <ProductPrimaryAction
              id="gtm-atc-primary"
              disabled={
                (availableForSale && product.ukOnly && currency === 'USD') ||
                loading
              }
              variant={ButtonTypes.PRIMARY}
              bg={Bg.black}
              onClick={handlePrimaryAction(
                status as Sanity.ProductStatus,
                availableForSale
              )}
              isFullWidth
              loading={productVariantId === lineItemMutatingID}
              hasMessage={status === Sanity.ProductStatus.PREORDER}
              testId="add-to-bag"
            >
              {getPrimaryCtaCopy(
                status! as Sanity.ProductStatus,
                !availableForSale,
                true
              )}
            </ProductPrimaryAction>
            {availableForSale && status === Sanity.ProductStatus.PREORDER && (
              <ProductPreorderAction
                onClick={handleNotifyClick}
                variant={ButtonTypes.TERTIARY}
              >
                <PortableText value={ukDisclaimer} />
              </ProductPreorderAction>
            )}
          </PrimaryActionContainer>
        </ProductActions>
        {product.ukOnly && currency === 'USD' ? (
          <ProductUKOnly>
            Unfortunately, we are unable to ship this product to the USA
          </ProductUKOnly>
        ) : null}
        {promoBanner ? <ProductPromoBanner {...promoBanner} /> : null}
        {(hasComparePrice?.showComparePrice && compareAtPrice
          ? shopifyProduct?.compareAtPrice
          : shopifyProduct?.price)! * 100 ? (
          <div
            style={{ marginTop: '1rem' }}
            dangerouslySetInnerHTML={{
              __html: `<klarna-placement
          data-key="credit-promotion-badge"
          data-locale="en-GB"
          data-purchase-amount="${
            (hasComparePrice?.showComparePrice && compareAtPrice
              ? shopifyProduct?.compareAtPrice
              : shopifyProduct?.price)! * 100
          }"
        ></klarna-placement>`,
            }}
          />
        ) : null}
        <ProductAccordions>
          {Array.isArray(tabs) &&
            tabs.map(
              (item, index) =>
                item && (
                  <Accordion
                    key={item._key}
                    item={{
                      label: item.label,
                    }}
                    initiallyOpen={index === 0}
                    isWithProduct
                    labelFontStyle={FONT_STYLE_SOFIA_11_500}
                  >
                    {item.label === 'Cannabinoid Content' ? (
                      <ResultsTable>
                        <thead>
                          <tr>
                            <th>Cannabinoid</th>
                            <th>Result</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Delta-9-tetrahydrocannabinol (Δ9-THC)</td>
                            <td>&#60; 0.007ppm</td>
                          </tr>
                          <tr>
                            <td>Tetrahydrocannabinolic acid (THCa)</td>
                            <td>&#60; 0.002ppm</td>
                          </tr>
                          <tr>
                            <td>Delta-8-tetrahydrocannabinol (Δ8-THC)</td>
                            <td>&#60; 0.009ppm</td>
                          </tr>
                          <tr>
                            <td>Cannabinol (CBN)</td>
                            <td>&#60; 0.009ppm</td>
                          </tr>
                          <tr>
                            <td colSpan={2}>
                              <p>
                                Each batch of product has been tested by an
                                independent analytical laboratory, which is
                                accredited in accordance with the recognised
                                International Standard ISO/IEC 17025:2017 as
                                awarded by the UKAS (United Kingdom
                                Accreditation Service).
                              </p>
                              <p>
                                All products are produced according to current
                                Cosmetic Good Manufacturing Practices (ISO
                                22716:2007).
                              </p>
                              <p>
                                This product has not been evaluated by the Food
                                and Drug Administration for safety and efficacy
                                and is not intended to diagnose, treat, cure, or
                                prevent any disease.
                              </p>
                            </td>
                          </tr>
                        </tbody>
                      </ResultsTable>
                    ) : null}
                    <ProductInner>
                      <TextRenderer
                        // @ts-expect-error no need for negativity
                        blocks={item.text}
                        bodyFontStyle={FONT_STYLE_SOFIA_14_500}
                      />
                    </ProductInner>
                  </Accordion>
                )
            )}
        </ProductAccordions>
        <ProductSharing>
          <SiteSharing
            variant={SiteSharingVariant.Product}
            emailSubject={title ?? ''}
            emailBody={'Check out this product from Cellular Goods –'}
          />
        </ProductSharing>
      </ContentWrap>
      {(!availableForSale ||
        status === Sanity.ProductStatus.INTEREST ||
        status === Sanity.ProductStatus.PREORDER) && (
        <PortalNotificationEmail
          productName={title ?? ''}
          status={
            status === Sanity.ProductStatus.INTEREST ||
            status === Sanity.ProductStatus.PREORDER
              ? (status as
                  | Sanity.ProductStatus.INTEREST
                  | Sanity.ProductStatus.PREORDER)
              : 'soldout'
          }
          isActive={showNotificationModal}
          copy={!availableForSale ? product.soldoutText : product.interestText}
          onCloseClick={handleNotificationClose}
        />
      )}
    </SectionWrap>
  )
}

export default OneTileProductPromo

const SectionWrap = styled.section`
  ${MEDIA_QUERIES.desktopUp} {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 4rem;
    gap: 4rem;
  }
`
const Tags = styled.div`
  display: flex;
  align-items: baseline;
  gap: 1rem;
  margin-bottom: 1rem;
`

const IsNew = styled(IsNewTag)`
  padding: 0.8rem 0.6rem;
  text-align: center;
  margin: 0;
  ${getFontStyles(FONT_STYLE_SOFIA_11_400)}
`

const ProductSoldout = styled(SoldOutOrnament)`
  display: inline-block;
  /* padding: 0.5rem 1rem; */
  text-align: center;
`
const OnSale = styled(OnSaleIndicator)`
  /* display: inline-block; */
  padding: 0.8rem 0.6rem;
  text-align: center;
  margin: 0;
`

const TitlePriceContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: baseline;

  ${MEDIA_QUERIES.desktopUp} {
    flex-direction: row;
    gap: 3rem;
  }
`

const PriceContainer = styled.div`
  display: flex;
  align-items: baseline;
  gap: 0.8rem;
  padding: 1rem 0;
`
const RRP = styled(Heading)`
  ${getFontStyles(FONT_STYLE_SOFIA_22_500)}
  color: var(--accessibleGrey);
  text-decoration: line-through;
  ${MEDIA_QUERIES.tabletUp} {
    min-width: 8rem;
  }
  ${MEDIA_QUERIES.desktopUp} {
    ${getFontStyles(FONT_STYLE_SOFIA_18_500)}
  }
`
const ImageWrap = styled.div`
  flex: 1;
`

const ContentWrap = styled.div`
  padding: 0 2rem 2.8rem 2rem;

  ${MEDIA_QUERIES.desktopUp} {
    padding: 0;
    flex: 1;
  }
`

const ProductActions = styled.div<{
  primaryCtaHasMessage: boolean
}>`
  margin-top: 3rem;

  ${MEDIA_QUERIES.desktopUp} {
    display: flex;
    align-items: ${(props) =>
      props.primaryCtaHasMessage ? 'flex-start' : 'flex-end'};
    margin-top: 2rem;
    gap: 2rem;
  }
`

const ProductQuantitySelector = styled(QuantitySelector)`
  margin-bottom: 1rem;

  ${MEDIA_QUERIES.desktopUp} {
    margin: 0;
  }
`

const ProductPrimaryAction = styled(Button)<{ hasMessage: boolean }>`
  display: none;

  ${MEDIA_QUERIES.desktopUp} {
    display: initial;
    margin-top: ${(props) => (props.hasMessage ? '2.4rem' : 0)};
  }
`

const ProductPrice = styled(Heading)``

const ProductDescription = styled(Text)`
  margin-top: 2rem;

  ${MEDIA_QUERIES.desktopUp} {
    margin-top: 4rem;
  }
`

const ProductMeta = styled.div`
  display: flex;
  flex-direction: column;

  ${MEDIA_QUERIES.desktopUp} {
    flex-direction: row;
    justify-content: flex-start;
    margin-top: 4rem;
    gap: 2rem;
  }
`

const ProductStars = styled.a`
  flex: 1 0 50%;
`

const ProductVariantSelection = styled(ProductVariantSelector)`
  margin-top: 3rem;
  order: 2;

  ${MEDIA_QUERIES.desktopUp} {
    order: 1;
    margin: 0;
    width: 21rem;
  }
`

const ProductAccordions = styled.div`
  margin-top: 3rem;

  ${MEDIA_QUERIES.desktopUp} {
    margin-top: 4rem;
  }
`

const ProductInner = styled.div`
  padding-bottom: 1rem;

  & p:first-child {
    margin-top: 0;
  }
`

const PrimaryActionContainer = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
`

const ProductPreorderAction = styled(Button)`
  display: inline-block;

  & > span {
    ${getFontStyles(FONT_STYLE_SOFIA_11_400)}
  }
`

const ProductSharing = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 2rem;
`

const MetaFlex = styled.div`
  display: flex;
  margin-top: 3rem;
  order: 1;
  flex: 1 0 50%;

  ${MEDIA_QUERIES.desktopUp} {
    order: 2;
    margin: 0;
    max-width: 44rem;
  }
`

const StrengthRating = styled.div`
  flex: 1 0 50%;
`

const StrengthTitle = styled(Heading)`
  margin-bottom: 1.3rem;
`

const StrengthBar = styled.div<{ $value: Sanity.ProductStrength['value'] }>`
  width: 13.6rem;
  height: 2.1rem;
  border: solid 1px var(--black);
  position: relative;

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: ${(props) =>
      props.$value === 'High'
        ? '100%'
        : props.$value === 'Medium'
        ? '80%'
        : '60%'};
    height: 100%;
    background-color: #dad5d5;
  }
`

const ResultsTable = styled.table`
  text-align: left;
  display: block;
  padding-bottom: 1rem;
  border-collapse: collapse;
  ${getFontStyles(FONT_STYLE_SOFIA_11_400)}

  & th,
  & td {
    padding: 0.5rem 0.75rem;
    border: solid 1px var(--black);
  }
`

const ProductUKOnly = styled.span`
  display: block;
  padding: 1rem 0 0 0;
  ${getFontStyles(FONT_STYLE_SOFIA_11_500)}
`
