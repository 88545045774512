import styled from 'styled-components'

import { Sanity } from '@cellulargoods/types'
import { Heading, Text } from '@cellulargoods/core'
import { MEDIA_QUERIES, FONT_STYLE_SOFIA_28_500 } from '@cellulargoods/styles'

export type InternalArticleQuoteProps =
  Sanity.Keyed<Sanity.InternalArticleQuote> & {
    showContent: boolean
  }

export const InternalArticleQuote = ({
  author,
  quote,
  showContent,
}: InternalArticleQuoteProps) => {
  const actualQuote = addQuoteMarks(quote ?? '')

  return (
    <ArticleQuoteSection style={{ display: showContent ? 'block' : 'none' }}>
      <ArticleQuote tag="h2" fontStyle={FONT_STYLE_SOFIA_28_500}>
        {actualQuote}
      </ArticleQuote>
      <ArticleAuthor>{author}</ArticleAuthor>
    </ArticleQuoteSection>
  )
}

const addQuoteMarks = (quote: string) => {
  let returnQuote = quote

  const firstLetter = quote.charAt(0)
  const lastLetter = quote.charAt(quote.length - 1)

  if (firstLetter !== '“') {
    returnQuote = `“${returnQuote}`
  }
  if (lastLetter !== '”') {
    returnQuote = `${returnQuote}”`
  }

  return returnQuote
}

const ArticleQuoteSection = styled.section`
  padding: 0 2rem;
  margin: 4rem 0;

  ${MEDIA_QUERIES.desktopUp} {
    grid-column: third / forth;
    padding: 0 4rem 0 4rem;

    margin: 3rem 0;
  }
`

const ArticleQuote = styled(Heading)``

const ArticleAuthor = styled(Text)`
  margin-top: 1.2rem;
  color: var(--darkGrey2);
`
