import styled from 'styled-components'
import { animated, useSpring } from '@react-spring/web'
import { useRef, useState, KeyboardEvent } from 'react'
import { X } from 'phosphor-react'
import isEmail from 'validator/lib/isEmail'
import FocusLock from 'react-focus-lock'

import {
  MEDIA_QUERIES,
  FONT_STYLE_SOFIA_11_400,
  FONT_STYLE_SOFIA_22_500,
} from '@cellulargoods/styles'
import {
  useWindowResize,
  useClickOutside,
  useKeyPress,
  useDisableScroll,
  useKlaviyo,
} from '@cellulargoods/hooks'
import {
  Text,
  ButtonTypes,
  Button,
  Bg,
  IconButton,
  Heading,
  Input,
  CheckboxInput,
  INVALID_EMAIL_ERROR,
  SERVER_ERROR,
  KLAVIYO_LIST_IDS,
} from '@cellulargoods/core'
import { Sanity } from '@cellulargoods/types'

import Alert from 'components/Cards/Alert'
import {
  SOLD_OUT_SUCCESS_MESSAGE,
  SUBSCRIPTION_SUCESS_MESAGE,
} from 'references/constants'

export type ModalNotificationProps = {
  isActive: boolean
  onCloseClick?: () => void
  copy?: string | null
  productName: string
  status:
    | Sanity.ProductStatus.INTEREST
    | Sanity.ProductStatus.PREORDER
    | 'soldout'
}

const NotificationBaseState = {
  inputValue: '',
  checkedValue: false,
  checkError: false,
  error: undefined,
  success: false,
  isSubmitting: false,
}

export const ModalNotification = ({
  isActive,
  onCloseClick,
  copy,
  productName,
  status,
}: ModalNotificationProps) => {
  const modalRef = useRef<HTMLDivElement>(null!)

  const [isDisplaying, setIsDisplaying] = useState(isActive)
  const [state, setState] = useState<{
    inputValue: string
    checkedValue: boolean
    checkError: boolean
    error?: string
    success: boolean
    isSubmitting: boolean
  }>(NotificationBaseState)

  const { subscribeEmailForProductUpdates } = useKlaviyo()

  const handleJoinMailingListSubmit = async (email: string) => {
    setState((state) => ({
      ...state,
      isSubmitting: true,
      success: false,
    }))

    try {
      // validate its actually an email
      const valueIsOkay = isEmail(email)
      const hasPrivacyBeenChecked = state.checkedValue

      if (!valueIsOkay || !hasPrivacyBeenChecked) {
        setState((state) => ({
          ...state,
          error: valueIsOkay ? undefined : INVALID_EMAIL_ERROR,
          checkError: !hasPrivacyBeenChecked,
          isSubmitting: false,
        }))
        return
      } else if (state.error === INVALID_EMAIL_ERROR || state.checkError) {
        setState((state) => ({
          ...state,
          error: valueIsOkay ? undefined : INVALID_EMAIL_ERROR,
          checkError: !hasPrivacyBeenChecked,
        }))
      }

      const { success } = await subscribeEmailForProductUpdates({
        email,
        captureSource: `PDP – ${productName}`,
        dateCreated: new Date().toISOString(),
        list:
          status === 'soldout'
            ? KLAVIYO_LIST_IDS.soldoutNotification
            : KLAVIYO_LIST_IDS.interestedInUpcomingProduct,
        productName,
        type:
          status === 'soldout'
            ? 'Products'
            : status === Sanity.ProductStatus.INTEREST
            ? 'Registered for Interest'
            : 'When Available',
      })

      if (success) {
        setState((state) => ({
          ...state,
          success: true,
          error: undefined,
          isSubmitting: false,
        }))
      } else if (!success) {
        setState((state) => ({
          ...state,
          success: false,
          error: SERVER_ERROR,
          isSubmitting: false,
        }))
      }
    } catch (err) {
      console.error(err)
    }
  }

  const handleChange = (text: string) => {
    setState((state) => ({
      ...state,
      inputValue: text,
    }))
  }

  const handleKeyDown = (e: KeyboardEvent<HTMLFormElement>) => {
    const { inputValue } = state
    if (e.key === 'Enter' && inputValue !== '') {
      handleJoinMailingListSubmit(inputValue)
    }
  }

  const handleClick = async () => {
    const { inputValue } = state
    if (inputValue !== '') {
      await handleJoinMailingListSubmit(inputValue)
    }
  }

  const handleCheckChange = (checked: boolean) => {
    setState((s) => ({
      ...s,
      checkedValue: checked,
    }))
  }

  const styles = useSpring({
    opacity: isActive ? 1 : 0,
    onStart: () => {
      if (isActive) {
        setIsDisplaying(true)
      }
    },
    onRest: () => {
      if (!isActive) {
        setIsDisplaying(false)
      }
    },
  })

  const { width, height } = useWindowResize()

  const handleCloseClick = () => {
    if (onCloseClick) {
      onCloseClick()
    }
    setState(NotificationBaseState)
  }

  useClickOutside(
    {
      current: [modalRef.current],
    },
    handleCloseClick
  )

  useKeyPress('Escape', handleCloseClick)

  useDisableScroll(isDisplaying)

  return (
    <NotificationBackground
      open={isDisplaying}
      style={{
        ...styles,
        width: `${width}px`,
        height: `${height}px`,
      }}
    >
      <FocusLock disabled={!isDisplaying}>
        <NotificationModal useMinHeight={state.success} ref={modalRef}>
          <CloseButton ariaLabel="Close dialog" onClick={handleCloseClick}>
            <X size={24} color="var(--black)" weight="thin" />
          </CloseButton>
          <NotificationContent>
            <NotificationTitle tag="h1" fontStyle={FONT_STYLE_SOFIA_22_500}>
              Notify me
            </NotificationTitle>
            <NotificationCopy>{copy}</NotificationCopy>
            {state.success ? (
              <NotificationAlert intent="success">
                <NotificationSuccess fontStyle={FONT_STYLE_SOFIA_11_400}>
                  {status === 'soldout'
                    ? SOLD_OUT_SUCCESS_MESSAGE
                    : SUBSCRIPTION_SUCESS_MESAGE}
                </NotificationSuccess>
              </NotificationAlert>
            ) : null}
            <NewsletterForm
              noValidate
              onSubmit={(e) => e.preventDefault()}
              onKeyDown={handleKeyDown}
              disabled={state.isSubmitting}
            >
              {!state.success ? (
                <>
                  <Input
                    label="Your email"
                    type="email"
                    value={state.inputValue}
                    error={state.error}
                    onChange={handleChange}
                    disabled={state.isSubmitting}
                  />
                  <CheckboxInput
                    label={[
                      `Yes, I would like to receive email updates from Cellular Goods on this product. Unsubscribe anytime. For more information, see our Privacy Policy.`,
                    ]}
                    error={state.checkError}
                    onChange={handleCheckChange}
                    checked={state.checkedValue}
                  />
                  <NotificationSubmit
                    type="submit"
                    variant={ButtonTypes.PRIMARY}
                    bg={Bg.black}
                    isFullWidth
                    onClick={handleClick}
                  >
                    Submit
                  </NotificationSubmit>
                </>
              ) : null}
            </NewsletterForm>
          </NotificationContent>
        </NotificationModal>
      </FocusLock>
    </NotificationBackground>
  )
}

const NotificationBackground = styled(animated.dialog)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  border: none;
  padding: 0;
  margin: 0;
  overflow: scroll;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.2);
  -webkit-backdrop-filter: blur(8px);
  backdrop-filter: blur(8px);

  &:not([open]) {
    display: none;
  }
`

const NotificationModal = styled.div<{
  useMinHeight: boolean
}>`
  min-height: ${(props) => (props.useMinHeight ? 'unset' : `41rem`)};
  width: 100%;
  background-color: var(--white);
  padding: 1.2rem 1.2rem 3rem 1.2rem;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  max-width: 52rem;
`

const CloseButton = styled(IconButton)`
  padding: 0.8rem;
`

const NotificationContent = styled.div`
  width: 100%;
  margin: 0.8rem 0 0;
  padding: 0 0.8rem;

  ${MEDIA_QUERIES.tabletUp} {
    padding: 0 2.8rem;
  }
`

const NotificationTitle = styled(Heading)``

const NotificationCopy = styled(Text)`
  margin: 2rem 0 1rem 0;
`

const NewsletterForm = styled.form<{
  disabled: boolean
}>`
  ${(props) =>
    props.disabled &&
    `
  pointer-events: none;
  opacity: 0.5;
 `}
`

const NotificationSubmit = styled(Button)`
  margin-top: 2rem;
`

const NotificationSuccess = styled(Text)`
  color: var(--white);
`

const NotificationAlert = styled(Alert)`
  margin-top: 2rem;
  margin-bottom: 1rem;
`
