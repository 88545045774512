import styled from 'styled-components'

import { Sanity } from '@cellulargoods/types'
import { MEDIA_QUERIES } from '@cellulargoods/styles'
import { BlockWithMarkDefs, TextRenderer } from '@cellulargoods/core'

export type InternalArticleRichTextProps =
  Sanity.Keyed<Sanity.InternalArticleRichText> & {
    className?: string
    showContent?: boolean
  }

export const InternalArticleRichText = ({
  richtext,
  className,
  showContent = true,
}: InternalArticleRichTextProps) => {
  return (
    <RichTextSection
      className={className}
      style={{ display: showContent ? 'block' : 'none' }}
    >
      <TextRenderer
        blocks={richtext as BlockWithMarkDefs[]}
        isInternalArticle
      />
    </RichTextSection>
  )
}

const RichTextSection = styled.section`
  padding: 0 2rem;
  margin: 4rem 0;

  ${MEDIA_QUERIES.desktopUp} {
    grid-column: third / forth;
    padding: 0 4rem 0 4rem;
    margin: 2.5rem 0;
  }
`
