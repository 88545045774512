import { Heading, slugify } from '@cellulargoods/core'
import {
  FONT_STYLE_SOFIA_16_400,
  FONT_STYLE_SOFIA_22_500,
  getFontStyles,
  MEDIA_QUERIES,
} from '@cellulargoods/styles'
import { Sanity } from '@cellulargoods/types'
import styled from 'styled-components'

export type InternalArticleContentsProps =
  Sanity.Keyed<Sanity.InternalArticleContents> & {
    showContent: boolean
  }

export const InternalArticleContents = ({
  contentsList,
  showContent,
}: InternalArticleContentsProps) => {
  return (
    <ContentsSection style={{ display: showContent ? 'block' : 'none' }}>
      <Heading tag="h2" fontStyle={FONT_STYLE_SOFIA_22_500}>
        Table of Contents
      </Heading>
      <ContentsList>
        {contentsList.map((item) => (
          <ContentsListItem key={slugify(item)}>
            <ContentsAnchor href={`#${slugify(item)}`}>{item}</ContentsAnchor>
          </ContentsListItem>
        ))}
      </ContentsList>
    </ContentsSection>
  )
}

const ContentsSection = styled.section`
  padding: 0 2rem;
  margin: 0 0 4rem;

  ${MEDIA_QUERIES.desktopUp} {
    grid-column: third / forth;
    padding: 0 4rem 0 4rem;
    margin: 0 0 3rem;
  }
`

const ContentsList = styled.ol`
  margin: 0;
  margin-top: 2rem;
  padding-left: 1.8rem;
`

const ContentsListItem = styled.li`
  &::marker {
    ${getFontStyles(FONT_STYLE_SOFIA_16_400)}
  }
`

const ContentsAnchor = styled.a`
  ${getFontStyles(FONT_STYLE_SOFIA_16_400)}
`
