import styled from 'styled-components'

import { RadioButton } from 'components/Input/RadioButton'

import { Sanity } from '@cellulargoods/types'
import { FONT_STYLE_SOFIA_11_400 } from '@cellulargoods/styles'
import { Text } from '@cellulargoods/core'

interface ProductVariantSelectorProps {
  variants: Sanity.ShopifyProductVariant[]
  activeVariant: Sanity.ShopifyProductVariant | null
  onChange: (variant: Sanity.ShopifyProductVariant | null) => void
  className?: string
  showLegend?: boolean
}

export const ProductVariantSelector = ({
  variants,
  activeVariant,
  onChange,
  className,
  showLegend = false,
}: ProductVariantSelectorProps) => {
  if (!variants) return null

  const handleVariantChange =
    (variant: Sanity.ShopifyProductVariant | null) => () =>
      onChange(variant)

  return (
    <RadioGroup className={className}>
      {showLegend ? (
        <RadioLegend tag="legend" fontStyle={FONT_STYLE_SOFIA_11_400}>
          Size
        </RadioLegend>
      ) : null}
      {variants?.map((variant) => (
        <RadioButton
          key={variant?.id}
          checked={activeVariant?.id === variant?.id}
          label={variant?.title as string}
          onChange={handleVariantChange(variant)}
        />
      ))}
    </RadioGroup>
  )
}

const RadioGroup = styled.fieldset`
  display: flex;
  gap: 10px;
  border: none;
  padding: 0;
  margin: 0;
`

const RadioLegend = styled(Text)`
  margin-bottom: 1rem;
`
