import { useRef, useState, useEffect } from 'react'
import { createPortal } from 'react-dom'

import {
  ModalNotification,
  ModalNotificationProps,
} from 'components/Modals/ModalNotification'

import { PORTAL_NODE_ID } from 'references/constants'

export const PortalNotificationEmail = (props: ModalNotificationProps) => {
  const ref = useRef<HTMLElement>(null!)
  const [mounted, setMounted] = useState(false)

  useEffect(() => {
    const el = document.getElementById(PORTAL_NODE_ID)
    ref.current = el!
    setMounted(true)
  }, [])

  return mounted
    ? createPortal(<ModalNotification {...props} />, ref.current)
    : null
}
